  .animation_hiden {
    opacity: 0;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    transition: opacity .9s .1s, -webkit-transform .9s ease;
    transition: opacity .9s .1s, transform .9s ease;
    transition: opacity .9s .1s, transform .9s ease, -webkit-transform .9s ease;
  }

  .animation_show {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }