.MuiPopover-paper {
  background-color: unset !important;
  box-shadow: unset !important;
}

.MuiSnackbarContent-message{
  width: 100%;
}

.MuiButtonBase-root:hover {
  background-color: unset !important;
}
